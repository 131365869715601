<template>
  <div>
    <main :style="{ paddingBottom: footerHeight + 'px' }">
      <section class="error">
        <div class="red text-center">
          <close class="close" :size="160" />
          <h1 class="text-uppercase">{{ $t("packetCheck.caution") }}</h1>
          <template v-if="!barcodeMatches">
            <h2>{{ $t("packetCheck.wrong-packet") }}</h2>
          </template>
          <template v-else>
            <h2>{{ $t("packetCheck.error-has-occurred") }}</h2>
          </template>

          <template v-if="!barcodeMatches">
            <p v-html="$t('packetCheck.find-correct-packet')"></p>
          </template>
          <template v-else>
            <ul>
              <li v-for="error in errors" :key="error.code">
                <p>{{ error.text }}</p>
              </li>
            </ul>
          </template>
        </div>
      </section>
    </main>
    <footer ref="footer">
      <div class="container">
        <a
          href="#"
          class="btn btn-primary btn-green"
          @click.prevent="$emit('open-scan')"
          >{{ $t("packetCheck.load-again") }}</a
        >

        <a
          href="#"
          class="a-black"
          @click.prevent="$emit('close-packet-detail')"
          >{{ $t("packetCheck.release-cancel") }}</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { setActualFooterHeight } from "@/mixins/footer";
import Close from "vue-material-design-icons/Close.vue";

export default {
  name: "PacketCheckError",
  mixins: [setActualFooterHeight],
  components: {
    Close
  },
  props: {
    barcodeMatches: {
      type: Boolean,
      required: true
    }
  },

  async mounted() {
    this.setActualFooterHeight(); // Set footer height after data fetch
  },

  computed: {
    ...mapState(["errors"])
  }
};
</script>

<style lang="scss" scoped>
.error {
  padding: 0 3rem;
  margin-top: 2.5rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  h2 {
    font-size: 1rem;
  }

  p {
    color: $light-grey;
  }
}

footer {
  a {
    margin-bottom: 10px;

    &.a-black:last-child {
      display: inline-block;
      margin: 16px 0 26px;
    }
  }
}

.close {
  svg {
    margin: -20px 0 -20px;
  }
}
</style>
