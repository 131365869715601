import { formatPrice, getCurrencySymbol } from "@/util/format-price";

export const formatCod = {
  methods: {
    formatPrice,
    getCurrencySymbol,

    formatCod: packet => {
      const { amount_cents, currency } = packet.cod;

      return formatPrice(amount_cents, currency);
    },

    formatRoundedCod: packet => {
      const { rounded_amount_cents, currency } = packet.cod;

      return formatPrice(rounded_amount_cents, currency);
    }
  }
};
