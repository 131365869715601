import { MOBILE_TYPES } from "@/util/mobile.type";

export const sendBarcodeResultToMobile = {
  methods: {
    sendBarcodeResultToMobile(barcodeResult) {
      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.sendCodeResult(barcodeResult);
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.sendCodeResult.postMessage(
            barcodeResult
          );
        }
      }
    }
  }
};
