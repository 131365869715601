<template>
  <section class="overlay-accept text-center">
    <div class="content white">
      <check-icon :size="190" />
      <h1>{{ heading }}</h1>
    </div>
  </section>
</template>

<script>
import CheckIcon from "vue-material-design-icons/Check.vue";

export default {
  name: "OverlayAccept",
  components: { CheckIcon },
  props: {
    heading: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay-accept {
  @include overlay;
  background: rgba(0, 192, 81, 0.9);

  .content {
    margin-top: 150px;
  }
}
</style>
