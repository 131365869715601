import getActualLanguage from "@/util/language";

const FALLBACK_LOCALE = process.env.VUE_APP_I18N_LOCALE || "cs";
const FORMATTER_OPTIONS = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  style: "currency"
};

const getFormatter = (locale, currency) => {
  return new Intl.NumberFormat(locale, {
    ...FORMATTER_OPTIONS,
    currency
  });
};

export const getCurrencySymbol = currency => {
  const locale = getActualLanguage() || FALLBACK_LOCALE;
  const formatter = getFormatter(locale, currency);
  const parts = formatter.formatToParts(0);
  const symbol = parts.find(({ type }) => type === "currency").value;

  return symbol;
};

/**
 * Format amount_cents and currency symbol into a price string.
 * @param {number} amountCents - amount in cents
 * @param {string} currency - currency identifier
 *
 * @example
 *  formatPrice(1, "USD");        // => "$0.01" (if locale is en-US)
 *  formatPrice(1050, "EUR");     // => "10,5 €" (if locale is sk-SK)
 *  formatPrice(100000, "CZK");   // => "1 000 Kč" (if locale is cs-CZ)
 */
export const formatPrice = (amountCents, currency) => {
  if (!currency || typeof amountCents !== "number") return;

  const locale = getActualLanguage() || FALLBACK_LOCALE;
  const formatter = getFormatter(locale, currency);
  const formattedPrice = formatter.format(amountCents / 100);

  return formattedPrice;
};

export default formatPrice;
